import { GetTrackByGuidApiResponse_v1 } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const getTrackByGuidAsync = async (trackGuid: string) => {
  const client = await apiClientFactory.buildWithScopeStringAsync(
    ApiTypes.Music,
    ["tasks_read"]
  );
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/track/${trackGuid}`,
  };

  return client
    .request<GetTrackByGuidApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetTrackByGuid error.", data.errors);
        throw new Error("Error getting track.");
      }

      // Return payload.
      return data.payload.track;
    });
};
