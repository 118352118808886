import {
  BaseSubscriptionDto_v1,
  InsertSubscriptionApiResponse_v1,
  InsertSubscriptionRequest_v1,
  ScopeTypes,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const insertSubscriptionAsync = async (
  subscription: BaseSubscriptionDto_v1
) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes.subscription_insert
  );
  const config: AxiosRequestConfig<InsertSubscriptionRequest_v1> = {
    data: { subscription: subscription },
    method: "POST",
    url: `/api/v1/subscription`,
  };

  return client
    .request<InsertSubscriptionApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("InsertSubscriptionAsync error.", data.errors);
        throw new Error("Error adding subscription.");
      }
      // Return payload.
      return data.payload.subscriptionGuid;
    });
};
