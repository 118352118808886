import { GetSubscriptionsPublicApiResponse_v1 } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";

import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const getSubscriptionsPublicByRoleGuidAsync = async (
  roleGuid: string
) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Identity);
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/subscription/public/role/${roleGuid}`,
  };

  return client
    .request<GetSubscriptionsPublicApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetSubscriptionsPublicByRoleAsync error.", data.errors);
        throw new Error("Error getting public subscriptions.");
      }
      // Return payload.
      return data.payload.subscriptions;
    });
};
