import {
  GetSubscriptionsByProfileGuidApiResponse_v1,
  ScopeTypes,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getSubscriptionsByProfileGuidAsync = async (
  profileGuid: string
) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes._default
  );
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/subscription/profile/${profileGuid}`,
  };

  return client
    .request<GetSubscriptionsByProfileGuidApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetSubscriptionByProfileGuidAsync error.", data.errors);
        throw new Error("Error getting subscription.");
      }

      // Return payload.
      return data.payload.subscriptions;
    });
};
