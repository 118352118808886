import {
  GetUserByAzureAdIdApiResponse_v1,
  ScopeTypes,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";

import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const getUserByAzureGuidAsync = async () => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes._default
  );
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/api/v1/user",
  };

  return client
    .request<GetUserByAzureAdIdApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetUserByAzureGuidAsync error.", data.errors);
        throw new Error("Error getting user.");
      }

      // Return payload.
      return data.payload.user;
    });
};
