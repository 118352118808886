import {
  BaseProfileDto_v1,
  InsertProfileApiResponse_v1,
  InsertProfileRequest_v1,
  ScopeTypes,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const insertProfileAsync = async (
  userGuid: string,
  roleGuid: string,
  subscriptionGuid: string,
  profile: BaseProfileDto_v1
) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes.profile_insert
  );
  const config: AxiosRequestConfig<InsertProfileRequest_v1> = {
    data: {
      userGuid: userGuid,
      roleGuid: roleGuid,
      subscriptionGuid: subscriptionGuid,
      profile: profile,
    },
    method: "POST",
    url: `/api/v1/profile`,
  };

  return client
    .request<InsertProfileApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("InsertProfileAsync error.", data.errors);
        throw new Error("Error creating profile.");
      }

      // Return payload.
      return data.payload.profileGuid;
    });
};
