import {
  ContentEnum,
  GetSlideResponse_v1,
  GetSlidesAsyncProps,
} from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getSlidesAsync = async (props: GetSlidesAsyncProps) => {
  const { content, contentGuid } = props;
  const client = await apiClientFactory.buildAsync(ApiTypes.Music);

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/slides/${ContentEnum[content]}/${contentGuid}`,
  };

  return client.request<GetSlideResponse_v1[]>(config).then((response) => {
    return response.data;
  });
};

