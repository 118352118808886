import { GetProfilesApiResponse_v1, ScopeTypes } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getProfileListByGuidsAsync = async (profileGuids: string[]) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes._default
  );

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/profile/list?profileGuids=${profileGuids.join(",")}`,
  };

  return client
    .request<GetProfilesApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetProfilesAsync error.", data.errors);
        throw new Error("Error getting profiles.");
      }

      // Return payload.
      return data.payload.profiles;
    });
};
