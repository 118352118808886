import { getSlidesAsync } from "@bu/lib-services";
import { GetSlidesAsyncProps } from "@bu/lib-types/music";
import { useQuery } from "@tanstack/react-query";

export const useMobileGetSlidesQuery = (props: GetSlidesAsyncProps) => {
  const initialQuery = useQuery({
    queryKey: ["getSlidesInfiniteQuery", props.contentGuid],
    queryFn: () => {
      const args = {
        content: props.content,
        contentGuid: props.contentGuid,
      };
      return getSlidesAsync(args);
    },
    enabled: !!props.contentGuid,
  });

  return {
    slides: initialQuery.data,
    isLoadingSlides: initialQuery.isLoading,
  };
};

