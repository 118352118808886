const getEnumKeyHelper = <TEnum>(enumType: { [key: string]: TEnum }) => {
  return (values: TEnum[]): string[] => {
    const keys = new Set<string>();

    for (const value of values) {
      let found = false;
      for (const key in enumType) {
        if (enumType[key] === value) {
          keys.add(key);
          found = true;
          break;
        }
      }
      if (!found) {
        throw new Error(
          `Value '${value}' does not exist in the provided enum.`
        );
      }
    }

    return Array.from(keys);
  };
};

export const getEnumKeys = <TEnum>(
  enumType: { [key: string]: TEnum },
  values: TEnum[]
): string[] => {
  const helper = getEnumKeyHelper(enumType);
  return helper(values);
};

export const getEnumKey = <TEnum>(
  enumType: { [key: string]: TEnum },
  value: TEnum
): string => {
  const result = getEnumKeys(enumType, [value]);
  return result?.length ? result[0] : "";
};
