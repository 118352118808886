import { ScopeTypes } from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";

import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

// TODO: Get this implemented. :)
export const GetProfileAliasAvailabilityAsync = async (alias: string) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes._default
  );
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/api/v1/profile/alias/{alias}/availability",
  };

  return Promise.resolve(Math.random() > 0.5);
};
