import { DeleteReleaseApiResponse_v1, ScopeTypes } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const deleteReleaseByGuidAsync = async (releaseGuid: string) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Music,
    ScopeTypes,
    ScopeTypes.release_delete
  );
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/release/${releaseGuid}`,
  };

  return client
    .request<DeleteReleaseApiResponse_v1>(config)
    .then((response) => response.data.isSuccess);
};
