import { SilentRequest } from "@azure/msal-browser";
import { MsalSingleton } from "@bu/lib-auth";
import { getCurrentConfig } from "@bu/lib-config";
import { getEnumKeys } from "@bu/lib-utils";
import axios, { CreateAxiosDefaults } from "axios";

export enum ApiTypes {
  Identity,
  LegacyApi,
  Music,
  Mobile,
}

const getCurrentProfileGuid = () => {
  //@ts-expect-error This is an arbitrary value that was added to the config object.
  const activeProfileGuid = getCurrentConfig().activeProfileGuid;

  // console.debug("Active profile guid", activeProfileGuid);
  return activeProfileGuid;
  //return "2CA61C53-C4EB-4508-AFF6-93F7B9A6BF48";
};

const getAccessTokenAsync = async (scopes: string[]) => {
  const instance = MsalSingleton.getInstance();
  const request: SilentRequest = {
    scopes: scopes,
  };

  let activeAccount = instance.getActiveAccount();
  const allAccounts = instance.getAllAccounts();
  if (!activeAccount && allAccounts?.length) {
    activeAccount = allAccounts[0];
    instance.setActiveAccount(activeAccount);
    console.log("Setting active account manually", activeAccount);
  }

  if (activeAccount) {
    const token = await instance.acquireTokenSilent(request);
    return token.accessToken;
  }

  console.log("No active account found");
  return "";
};

const getClientAsync = async (
  apiType: ApiTypes,
  addAuth: boolean,
  scopes: string[]
) => {
  const config = getCurrentConfig();
  let baseUrl = "";
  let scopeUrlPrefix = "";

  switch (apiType) {
    case ApiTypes.Identity:
      baseUrl = config.API_BASEURL_IDENTITY;
      scopeUrlPrefix = `${config.AZURE_B2C_BASEURL_SCOPE}/identity`;
      break;

    case ApiTypes.LegacyApi:
      baseUrl = config.API_BASEURL_LEGACY;
      scopeUrlPrefix = `${config.AZURE_B2C_BASEURL_SCOPE}/legacy`;
      break;

    case ApiTypes.Music:
      baseUrl = config.API_BASEURL_MUSIC;
      scopeUrlPrefix = `${config.AZURE_B2C_BASEURL_SCOPE}/music`;
      break;

    case ApiTypes.Mobile:
      baseUrl = config.API_BASEURL_MOBILE;
      break;
  }

  if (!baseUrl.endsWith("/")) {
    baseUrl = `${baseUrl}/`;
  }

  const axiosConfig: CreateAxiosDefaults = {
    baseURL: baseUrl,
    headers: {
      "Content-type": "application/json",
    },
  };

  if (addAuth) {
    // Build scopes here.
    const scopeURLs = scopes.map((scope) => {
      return `${scopeUrlPrefix}/${scope}`;
    });

    const currentProfileGuid = getCurrentProfileGuid();

    const accessToken = await getAccessTokenAsync(scopeURLs);
    axiosConfig.headers = {
      ...axiosConfig.headers,
      Authorization: `Bearer ${accessToken}`,
      "x-bu-profile-guid": currentProfileGuid,
    };
  }

  return axios.create(axiosConfig);
};

export const apiClientFactory = {
  buildAsync: (apiType: ApiTypes) => {
    return getClientAsync(apiType, false, []);
  },
  buildWithScopeStringAsync: async (apiType: ApiTypes, scopes: string[]) => {
    return getClientAsync(apiType, true, scopes);
  },
  buildWithScopeAsync: async <TEnum>(
    apiType: ApiTypes,
    enumType: { [key: string]: TEnum },
    scope: TEnum
  ) => {
    return apiClientFactory.buildWithScopesAsync(apiType, enumType, [scope]);
  },
  buildWithScopesAsync: async <TEnum>(
    apiType: ApiTypes,
    enumType: { [key: string]: TEnum },
    scopes: TEnum[]
  ) => {
    const scopeKeys = getEnumKeys(enumType, scopes);
    return getClientAsync(apiType, true, scopeKeys);
  },
};
