import { getContentAsync } from "@bu/lib-services";
import { ContentEnum } from "@bu/lib-types/music";
import { useQuery } from "@tanstack/react-query";

type AVAILABLE_CONTENTS = keyof typeof ContentEnum;

export const useGetContentQuery = (content: AVAILABLE_CONTENTS) => {
  const { data, isLoading } = useQuery({
    queryFn: (_) => getContentAsync(ContentEnum[content]),
    queryKey: ["content", content],
  });

  return { contents: data, isContentLoading: isLoading };
};

