import {
  ProfileDto_v1,
  ScopeTypes,
  UpdateProfileApiResponse_v1,
  UpdateProfileRequest_v1,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";

import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const updateProfileAsync = async (profile: ProfileDto_v1) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes.profile_update
  );
  const config: AxiosRequestConfig<UpdateProfileRequest_v1> = {
    data: {
      profile: profile,
    },
    method: "PATCH",
    url: `/api/v1/profile/${profile.guid}`,
  };

  return client
    .request<UpdateProfileApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("UpdateProfileAsync error.", data.errors);
        throw new Error("Error updating profile.");
      }

      // Return payload.
      return data.isSuccess;
    });
};
