import {
  DeleteSocialLinkApiResponse_v1,
  ScopeTypes,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const deleteSocialLinkAsync = async (socialLinkGuid: string) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes.socialLink_delete
  );
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/socialLink/${socialLinkGuid}`,
  };

  return client
    .request<DeleteSocialLinkApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("DeleteSocialLinkAsync error.", data.errors);
        throw new Error("Error deleting socialLink.");
      }

      // Return payload.
      return data.payload;
    });
};
