import { useIsAuthenticated } from "@azure/msal-react";
import {
  deleteSocialLinkAsync,
  getSocialLinksByProfileGuidAsync,
  insertSocialLinkAsync,
  updateSocialLinkAsync,
} from "@bu/lib-services";
import { BaseSocialLinkDto_v1, SocialLinkDto_v1 } from "@bu/lib-types/identity";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useProfileSocialLinksQuery = (profileGuid: string) => {
  const queryKey = ["profile", "socialLink", profileGuid];
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryFn: () => {
      return getSocialLinksByProfileGuidAsync(profileGuid).then(
        (socialLinks) => {
          socialLinks.forEach((socialLink) => {
            queryClient.setQueryData(
              ["socialLink", socialLink.guid],
              socialLink
            );
          });
          return socialLinks;
        }
      );
    },

    queryKey: queryKey,
    enabled: isAuthenticated,
    staleTime: 0,
  });

  const insertMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (baseSocialLink: BaseSocialLinkDto_v1) =>
      insertSocialLinkAsync(profileGuid, baseSocialLink),
    onSuccess: (newSocialLinkGuid, baseSocialLink) => {
      const newSocialLink = {
        guid: newSocialLinkGuid,
        profileGuid: profileGuid,
        ...baseSocialLink,
      };
      queryClient.setQueryData(
        queryKey,
        (oldSocialLinks: SocialLinkDto_v1[]) => {
          return [...oldSocialLinks, newSocialLink];
        }
      );
      queryClient.setQueryData(
        ["socialLink", newSocialLinkGuid],
        newSocialLink
      );
    },
  });

  const updateMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (mutationArgs: {
      profileGuid: string;
      socialLink: SocialLinkDto_v1;
    }) =>
      updateSocialLinkAsync(mutationArgs.profileGuid, mutationArgs.socialLink),
  });

  const deleteMutation = useMutation({
    mutationKey: queryKey,
    mutationFn: (socialLinkGuid: string) =>
      deleteSocialLinkAsync(socialLinkGuid),
    onMutate: (variables) => {
      queryClient.setQueryData(
        queryKey,
        (oldSocialLinks: SocialLinkDto_v1[]) => {
          return oldSocialLinks.filter(
            (socialLink) => socialLink.guid !== variables
          );
        }
      );
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    socialLinks: query.data,

    insertSocialLinkAsync: insertMutation.mutateAsync,
    updateSocialLinkAsync: updateMutation.mutateAsync,
    deleteSocialLinkAsync: deleteMutation.mutateAsync,

    isSocialLinksLoading: query.isLoading,
    isSocialLinksError: query.isError,
  };
};
