import { GetTracksByReleaseGuidApiResponse_v1 } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getMobileTrackListAsync = async (guids: string[]) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Music);

  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/api/v1/track/list",
    params: {
      guids: guids.join(","),
    },
  };

  return client
    .request<GetTracksByReleaseGuidApiResponse_v1>(config)
    .then((response) => {
      return response.data;
    });
};

