import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useContext, useState } from "react";

interface QueryContextType {
  queryClient: QueryClient;
}

export const QueryContext = createContext<QueryContextType>(
  {} as QueryContextType
);

interface Props extends PropsWithChildren {}

export const QueryProvider = (props: Props) => {
  const { children } = props;

  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 5,
        },
      },
    });
  });

  // Check if the context is already loaded.
  const selfContext = useContext(QueryContext);
  if (selfContext.queryClient) {
    console.debug("QueryProvider already loaded, bypassing.");
    return <>{children}</>;
  }

  return (
    <QueryContext.Provider value={{ queryClient }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </QueryContext.Provider>
  );
};

