import {
  ReleaseDto_v1,
  ScopeTypes,
  UpdateReleaseApiResponse_v1,
  UpdateReleaseRequest_v1,
} from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const updateReleaseAsync = async (release: ReleaseDto_v1) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Music,
    ScopeTypes,
    ScopeTypes.release_update
  );
  const config: AxiosRequestConfig<UpdateReleaseRequest_v1> = {
    data: {
      release,
    },
    method: "PATCH",
    url: `/api/v1/release/${release.guid}`,
  };

  return client
    .request<UpdateReleaseApiResponse_v1>(config)
    .then((response) => response.data);
};
