import { GetTracksByReleaseGuidApiResponse_v1 } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const getTracksByGuidListAsync = async (guidList: string[]) => {
  const client = await apiClientFactory.buildWithScopeStringAsync(
    ApiTypes.Music,
    ["tasks_read"]
  );
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/track/list`,
    data: {
      guids: guidList,
    },
  };

  return client
    .request<GetTracksByReleaseGuidApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetTracksByGuidList error.", data.errors);
        throw new Error("Error getting tracks.");
      }

      // Return payload.
      return data.payload.tracks;
    });
};
