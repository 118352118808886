import { AppConfiguration } from "./appConfiguration";
import { aksDevelopmentConfig } from "./configs/config.aks.development";
import { aksProductionConfig } from "./configs/config.aks.production";
import { aksSandboxConfig } from "./configs/config.aks.sandbox";
import { localDevelopmentConfig } from "./configs/config.development";
import { Environment } from "./environment";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let global: any;
declare global {
  interface Window {
    config: AppConfiguration;
  }
}

const DEFAULT_CONFIG = aksDevelopmentConfig;

export const getConfigByEnvironment = (env: Environment) => {
  switch (env) {
    case Environment.LOCAL_DEVELOPMENT:
      return localDevelopmentConfig;

    case Environment.DEVELOPMENT:
      return aksDevelopmentConfig;

    case Environment.SANDBOX:
      return aksSandboxConfig;

    case Environment.PRODUCTION:
      return aksProductionConfig;

    default:
      return aksDevelopmentConfig;
  }
};

export const getCurrentConfig = () => {
  ("undefined" != typeof global ? global : window).config = {
    ...DEFAULT_CONFIG,
    ...process.env,
    ...window.config,
  };

  return ("undefined" != typeof global ? global : window)
    .config as AppConfiguration;
};

export const setCurrentConfig = (env: Environment) => {
  ("undefined" != typeof global ? global : window).config =
    getConfigByEnvironment(env);
};

export const setCurrentConfigValue = (variableName: string, value: string) => {
  ("undefined" != typeof global ? global : window).config = {
    ...getCurrentConfig(),
    [variableName]: value,
  };
};
