import { getProfileByGuidAsyncMock } from "@bu/lib-services";
import { useQuery } from "@tanstack/react-query";

export const useProfileByIdQuery = (profileGuid: string) => {
  const profileByGuidQuery = useQuery({
    queryFn: async () => getProfileByGuidAsyncMock(profileGuid),
    queryKey: ["profileByGuid", profileGuid],
  });

  return {
    profile: profileByGuidQuery.data,
    isLoading: profileByGuidQuery.isLoading,
    isFetching: profileByGuidQuery.isFetching,
  };
};

