import { DeleteTrackApiResponse_v1, ScopeTypes } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const deleteTrackAsync = async (trackGuid: string) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Music,
    ScopeTypes,
    ScopeTypes.track_delete
  );
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/track/${trackGuid}`,
  };

  return client
    .request<DeleteTrackApiResponse_v1>(config)
    .then((response) => response.data);
};
