import {
  GetSocialLinksByProfileGuidApiResponse_v1,
  ScopeTypes,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getSocialLinksByProfileGuidAsync = async (profileGuid: string) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes._default
  );
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/socialLink/profile/${profileGuid}`,
  };

  return client
    .request<GetSocialLinksByProfileGuidApiResponse_v1>(config)
    .then((response) => response.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess || !data.payload) {
        console.error("GetSocialLinkByProfileGuidAsync error.", data.errors);
        throw new Error("Error getting socialLink.");
      }

      // Return payload.
      return data.payload.socialLinks;
    });
};
