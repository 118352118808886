import { ContentEnum, GetContentResponse } from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const getContentAsync = async (content: ContentEnum) => {
  const client = await apiClientFactory.buildAsync(ApiTypes.Music);

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/content/${content}/`,
  };

  return client.request<GetContentResponse>(config).then((response) => {
    return response.data;
  });
};

