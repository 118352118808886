import {
  ContentEnum,
  GetSlideResponse_v1,
  GetSlidesAsyncProps,
} from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

type Props = GetSlidesAsyncProps & {
  pageNo: number;
  slideGuid: string;
};

export const getSlidesPaginatedAsync = async (props: Props) => {
  const { content, contentGuid, pageNo, slideGuid } = props;
  const client = await apiClientFactory.buildAsync(ApiTypes.Music);

  const config: AxiosRequestConfig = {
    method: "GET",
    url: `/api/v1/slides/${ContentEnum[content]}/${contentGuid}/slide/${slideGuid}?pageNo=${pageNo}`,
  };

  return client.request<GetSlideResponse_v1>(config).then((response) => {
    return response.data;
  });
};

