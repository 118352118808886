import {
  ScopeTypes,
  TrackDto_v1,
  UpdateTrackApiResponse_v1,
  UpdateTrackRequest_v1,
} from "@bu/lib-types/music";
import { AxiosRequestConfig } from "axios";

import { apiClientFactory, ApiTypes } from "../../apiClientFactory";

export const updateTrackAsync = async (track: TrackDto_v1) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Music,
    ScopeTypes,
    ScopeTypes.track_update
  );
  const config: AxiosRequestConfig<UpdateTrackRequest_v1> = {
    data: {
      track,
    },
    method: "PATCH",
    url: `/api/v1/track/${track.guid}`,
  };

  return client
    .request<UpdateTrackApiResponse_v1>(config)
    .then((response) => response.data);
};
