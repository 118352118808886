import {
  DeleteSubscriptionApiResponse_v1,
  ScopeTypes,
} from "@bu/lib-types/identity";
import { AxiosRequestConfig } from "axios";
import { ApiTypes, apiClientFactory } from "../../apiClientFactory";

export const deleteSubscriptionAsync = async (subscriptionGuid: string) => {
  const client = await apiClientFactory.buildWithScopeAsync(
    ApiTypes.Identity,
    ScopeTypes,
    ScopeTypes.subscription_delete
  );
  const config: AxiosRequestConfig = {
    method: "DELETE",
    url: `/api/v1/subscription/${subscriptionGuid}`,
  };

  return client
    .request<DeleteSubscriptionApiResponse_v1>(config)
    .then((res) => res.data)
    .then((data) => {
      // Check for errors.
      if (!data.isSuccess) {
        console.error("DeleteSubscriptionAsync error", data.errors);
        throw new Error("Error deleting subscription.");
      }
      // Return payload.
      return data.payload;
    });
};
